@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        padding: 15rem 0 5rem 0;

        lottie-player {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            height: auto;
        }
    }
}